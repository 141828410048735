import React from 'react';
import Layout from '../shared/Layout';
import DoubleLayout from '../shared/DoubleLayout';


const MintWebTutsPlatform = () => {
    return (
        <Layout>
            <DoubleLayout>
                <h1>Aici explic mai multe lucruri despre platforma mintweb tutts</h1>
            </DoubleLayout>
        </Layout>
    );
}
export default MintWebTutsPlatform;